<template>
  <el-dialog :visible.sync="showDialog"  @close="closeDialog" class="custom-dialog radio-dialog" :class="showFolder ? 'is-folder' : ''" :title="showFolder ? '选择样例库' : '选择演示样例'" width="885px">
    <div class="back-btn" v-if="isFolder && showSubData" @click="onBack"><i class="el-icon-arrow-left"></i>返回</div>
    <div class="message-tip" v-if="showFolder || !isFolder">
      <span>提示：{{ tip }}（样例更新时间：2022年5月）</span>
    </div>
    <div>
      <ul class="example-item" v-if="showFolder">
        <li class="image-item custom-img">
          <div class="inner">
            <el-upload
              :action="actionUrl"
              :before-upload="handleBeforeUpload"
              :on-change="handleChangeUpload"
              :on-error="handleUploadError"
              :on-success="handleUploadSuccess"
              :show-file-list="false"
              :headers="{token}"
              :accept="accept"
              name="pic">添加本地</el-upload>
          </div>
        </li>
        <li class="image-item" v-for="(item, index) in dataList" :key="index">
          <div class="inner" :class="activeFolderIndex == index ? 'choose' : ''" @click="chooseFolder(item, index)">
            <img :src="iconFolder" alt="" class="icon-folder" width="70px" height="58px">
            <div class="file-name">{{item.filePath}}</div>
          </div>
        </li>
      </ul>
      <ul class="example-item" v-if="showSubData">
        <li class="image-item" v-for="(item,index) in subDataList" :key="index">
          <div class="inner" :class="fileUrl == item.fileUrl ? 'choose' : ''" @click="chooseItem(item.fileUrl)">
            <el-image :src="item.fileUrl" lazy fit="contain" class="image-url" v-if="isImage">
              <div slot="placeholder" v-loading="true" class="loading"></div>
            </el-image>
            <video :src="item.fileUrl" x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" x5-video-player-fullscreen="" x5-video-orientation="portraint" class="video" v-else>您的浏览器不支持 video 标签。</video>
            <div class="file-name">{{item.fileName}}</div>
          </div>
        </li>
      </ul>
      <ul class="example-item" v-if="!isFolder">
        <li class="image-item custom-img">
          <div class="inner">
            <el-upload
              :action="actionUrl"
              :before-upload="handleBeforeUpload"
              :on-change="handleChangeUpload"
              :on-error="handleUploadError"
              :on-success="handleUploadSuccess"
              :show-file-list="false"
              :headers="{token}"
              :accept="accept"
              name="pic">添加本地</el-upload>
          </div>
        </li>
        <li class="image-item" v-for="(item,index) in dataList" :key="index">
          <div class="inner" :class="fileUrl == item ? 'choose' : ''" @click="chooseItem(item)">
            <el-image :src="item" lazy fit="contain" class="image-url" v-if="isImage">
              <div slot="placeholder" v-loading="true" class="loading"></div>
            </el-image>
            <video :src="item" x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" x5-video-player-fullscreen="" x5-video-orientation="portraint" class="video" v-else>您的浏览器不支持 video 标签。</video>
            <div class="file-name">样例{{index + 1}}</div>
          </div>
        </li>
      </ul>
    </div>
    <div slot="footer" class="dialog-footer" v-if="showSubData || !isFolder">
      <el-button type="primary"  @click="onSubmit" :disabled="disabled">确 认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { Loading } from 'element-ui';
import { STORAGE, CODE_CLASS } from '@/utils/const';
import { downloadImage, goLoginPageAndInitData } from '@/utils';

export default {
  name: "choose-example",
  props: {
    dataList: {
      type: Array,
    },
    type: {
      type: String,
    },
    fileType: {
      type: Number,
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    // 是否文件夹方式展示
    isFolder: {
      type: Boolean,
      default: true,
    }
  },
  components: {
  },
  data() {
    return {
      tip: this.accept == 'image/*' ? '样例仅作体验用途；请上传包含正面人脸的图片' : '样例仅作体验用途；请上传包含正面人脸的视频',
      fileUrl: '',
      showDialog: true,
      iconFolder: require('@/assets/img/icon-folder.png'),
      activeFolderIndex: undefined,
      showSubData: false,
      subDataList: [], // 二级文件数据
    };
  },

  computed: {
    isImage() {
      return this.accept == 'image/*';
    },
    token() {
      return localStorage.getItem(STORAGE.RJ_SPECIAL_TOKEN);
    },
    loading() {
      return !this.dataList.length;
    },
    actionUrl() {
      return `/api/create/uploadFile?fileType=${this.fileType}`
    },
    showFolder() {
      return this.isFolder && !this.showSubData;
    },
    disabled() {
      if (this.isFolder && !this.showSubData) {
        return this.activeFolderIndex == undefined;
      } else {
        return !this.fileUrl;
      }
    }
  },
  mounted() {
  },

  methods: {
    // 添加、上传成功、失败触发
    handleChangeUpload(file, fileList) {
      if (file.status == 'ready') {
        this.pageLoading = Loading.service({
          fullscreen: true,
          background: 'rgba(0,0,0,0.7)'
        })
      }
    },

    // 上传前判断
    handleBeforeUpload(file) {
      // const isLt5M = file.size / 1024 / 1024 < 5;
      // const isLt100M = file.size / 1024 / 1024 < 100;
      // if (key == 'image' ? !isLt5M : !isLt100M) {
      //   this.$message.error(key == 'image' ? '图片大小不能超过5M' : '视频大小不能超出100M');
      //   this.pageLoading.close();
      //   return false;
      // }
    },

    // 上传失败
    handleUploadError(response, file, fileList) {
      this.pageLoading.close();
      this.$message.error('上传失败');
    },

    // 上传成功
    handleUploadSuccess(response) {
      this.pageLoading.close();
      let {code, msg, data} = response;
      if (code != 200) {
        // 这里加上Login的判断，防止上传多张图片异常时多次跳转login，redirect就会重复叠加
        if (code == 401 && this.$route.name !== 'Login') {
          goLoginPageAndInitData(this.$route.fullPath);
        }
        this.$message.error(msg);
        return;
      }
      this.fileUrl = data;
      this.$emit('onChoose', this.fileUrl);
    },

    // 关闭右上角弹窗
    closeDialog(){
      this.$emit("closeDialogItem");
    },

    // 选择文件
    chooseItem(fileUrl) {
      this.fileUrl = fileUrl;
    },

    onSubmit() {
      if(!this.fileUrl){
        return false;
      }
      this.$emit('onChoose', this.fileUrl);
    },

    // 选择文件夹
    chooseFolder(item, index) {
      this.activeFolderIndex = index;
      this.showSubData = true;
      this.subDataList = this.dataList[this.activeFolderIndex].detailVos || [];
    },

    // 返回
    onBack() {
      this.showSubData = false;
      this.activeFolderIndex = undefined;
      this.fileUrl = '';
    },
  },
};
</script>

<style lang="less">
  @import "../assets/less/chooseExample";
</style>

